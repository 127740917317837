*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body{
    background: #FAFAFA;
    font: 14px sans-serif;
    -webkit-font-smoothing: antiliased !important;
}